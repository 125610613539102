/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 SAGATEC (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by SAGATEC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , {useState, useEffect} from "react";
import { Redirect } from "react-router";

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import logo from "assets/img/react-logo.png";

function Magasin(props) {
    
    let history = useHistory();
    const [data, setData] = useState( [] );
    var IDclient=-1;

   
    function Pass(IDmagasin){
        history.push({
            pathname: '/admin/dashboard',
          
            state: { detail : IDmagasin  }
        })
    }

    useEffect(()=>{
        axios.post("https://api.livecaisse.com/api/Magasins", {
            IDclient: IDclient,
        }).then((resp) =>{
            
            setData(resp.data);
    })}, []);





      if(props.location.state == undefined  ){
        return <Redirect to="/admin/login" />
      }else
      {    
        IDclient =  props.location.state.detail[0].IDclient;
  return (
    <>
    <Sidebar
              
               logo={{
                 outterLink: "",
                 text: "Dashboard",
                 imgSrc: logo,
               }}
               
             />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Vos magasins</h5>
                <p className="category">
                Sélectionnez le magasin que vous voulez 
                </p>
              </CardHeader>
              <CardBody className="all-icons">
                <Row>
                {data.map((val)=>{
                        return  <Col
                        className="font-icon-list col-xs-6 col-xs-6"
                        lg="2"
                        md="3"
                        sm="4"
                        onClick={() => Pass(val.IDmagasin)}
                      >
                        <div className="font-icon-detail">
                        <i className="tim-icons icon-square-pin" />
                        <h4> </h4>
                          <h4>{val.NomMagasin}</h4>
                        </div>
                      </Col>
                      })}
                 
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
}

export default Magasin;
