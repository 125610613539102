/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 SAGATEC (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by SAGATEC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  {useState, useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Axios from "axios";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import logo from "assets/img/react-logo.png";
import logo1 from "assets/img/faviconmcscloud.png";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
 
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
import { Redirect } from "react-router";

function Dashboard(props) 
{


  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  









  
  const [data, setData] = useState( [] );
  const [Annee, setAnnee] = useState( [] );
  const [Mois, setMois] = useState( [] );
  const [chart , setchart] = useState( [] );
  const [chartMois , setchartMois] = useState( [] );
  const [chartAnnee , setchartAnnee] = useState( [] );
  let history = useHistory();
  var IDmagasin = -1;
  var IDclient="";
  var client="";
  var GetData = "";
  var GetAnnee = "" ;
  var GatChart = "";
  var GetChartMois = "";
  var GetChartAnnee = "";
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  

useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/Mois", {
      IDclient: IDclient,
  }).then((resp) =>{

    setMois(resp.data);
})}, []);


useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/chart", {
    GatChart: GatChart,
  }).then((resp) =>{
    setchart(resp.data);
})}, []);



useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/chartAnnee", {
    GetChartAnnee: GetChartAnnee,
  }).then((resp) =>{
    console.log(resp.data)
    setchartAnnee(resp.data);
})}, []);

useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/chartMois", {
    GetChartMois: GetChartMois,
  }).then((resp) =>{
 
    setchartMois(resp.data);
})}, []);


useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/Annee", {
    GetAnnee: GetAnnee,
  }).then((resp) =>{
      
    setAnnee(resp.data);
})}, []);


useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/get", {
    GetData: GetData,
  }).then((resp) =>{
    
    setData(resp.data)
    
})}, []);

  














let chartExample1 = {
  data1: (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: 
        chart.map((val)=>{
          return val.DATE
        })
   
      ,
      datasets: [
        {
          label: "Total",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data:  chart.map((val)=>{
            return val.RECETTE
          }),
        },
      ],
    };
  },
  data2: (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: 
      chartMois.map((val)=>{
          return val.Mois
        })
      ,
      datasets: [
        {
          label: "Total",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: chartMois.map((val)=>{
            return val.TotalMois
          }),
        },
      ],
    };
  },
  data3: (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
    labels: 
    chartAnnee.map((val)=>{
          return val.Annee
       }),
      datasets: [
        {
          label: "Total ",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
           data: chartAnnee.map((val)=>{
            return val.TotalAnnée
           }),
        },
      ],
    };
  },
  options: chart1_2_options,
};

















console.log("idmagasin : "+props.location.state.detail)

if(props.location.state == undefined ){
  return <Redirect to="/admin/login" />
}else{

  var IDclient="SELECT ROUND(SUM(RECETTE),2) AS TotalMois FROM "+props.location.state.detail+"_GlobalCloud WHERE SUBSTRING_INDEX( DATE ,'/',-1) = YEAR(CURDATE()) and MONTH(CURDATE()) = SUBSTRING_INDEX(SUBSTRING_INDEX( DATE ,'/',-2) ,'/',1) LIMIT 1;";
  var GetData = "Select * from "+props.location.state.detail+"_GlobalCloud ORDER BY ID DESC LIMIT 1";
  var GetAnnee = "SELECT ROUND(SUM(RECETTE),2) AS TotalAnnée FROM "+props.location.state.detail+"_GlobalCloud WHERE YEAR(CURDATE()) = SUBSTRING_INDEX( DATE ,'/',-1) LIMIT 1;" 
  var GatChart = "Select * from "+props.location.state.detail+"_GlobalCloud WHERE SUBSTRING_INDEX(SUBSTRING_INDEX( DATE ,'/',-2) ,'/',1) =MONTH(CURRENT_DATE()) AND SUBSTRING_INDEX(SUBSTRING_INDEX( DATE ,'/',-1) ,'/',1) = YEAR(CURRENT_DATE()) ORDER BY ID DESC ";
  var GetChartMois = "SELECT SUBSTRING_INDEX(SUBSTRING_INDEX( DATE ,'/',-2) ,'/',1) AS Mois ,ROUND(SUM(RECETTE),2) AS TotalMois FROM "+props.location.state.detail+"_GlobalCloud WHERE SUBSTRING_INDEX( DATE ,'/',-1) = YEAR(CURDATE()) GROUP BY SUBSTRING_INDEX(SUBSTRING_INDEX( DATE ,'/',-2) ,'/',1) ORDER BY Mois desc LIMIT 12 ;"
  var GetChartAnnee = "SELECT ROUND(SUM(RECETTE),2) AS TotalAnnée ,  SUBSTRING_INDEX( DATE ,'/',-1) AS Annee FROM "+props.location.state.detail+"_GlobalCloud GROUP BY Annee ORDER BY Annee desc ;";
 IDmagasin = props.location.state.detail;
 

  function Pass(){
    history.push({
        pathname: '/admin/dashboard',
      
        state: { detail: IDmagasin }
    })
}

  return (
    <>
           <Sidebar
               routes={routes}
               logo={{
                 outterLink: "",
                 text: "Dashboard",
                 imgSrc: logo,
               }}
               IDmagasin={IDmagasin}
               toggleSidebar={toggleSidebar}
             />
      <div className="content">
        
      <Row>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail" onClick={Pass}>
                    
                    <img src={logo1}/>
                    <h5></h5>
                      
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                    
                    <i className="tim-icons icon-coins" />
                      
                      {data.map((val)=>{
                        return <p>Recette du {val.DATE}</p>
                      })}
                      {data.map((val)=>{
                        return <h4>{val.RECETTE} DH</h4>
                      })}
                      
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                    <i className="tim-icons icon-money-coins" />

                      <p>Recette de ce mois</p>
                      {Mois.map((val)=>{ 
                         return <h4>{val.TotalMois} DH</h4> 
                       })} 
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                    <i className="tim-icons icon-calendar-60" />
                      <p>Recette de l'année</p>
                      {Annee.map((val)=>{
                        return <h4>{val.TotalAnnée} DH</h4>
                      })}
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                    <i className="tim-icons icon-alert-circle-exc" />
                    
                      
                      {data.map((val)=>{
                        return <p>Annulation du {val.DATE} </p>
                      })}
                      {data.map((val)=>{
                        return <h4>{val.Annulation} DH</h4>
                      })}
                    </div>
                  </Col>
                  <Col
                    className="font-icon-list col-xs-6 col-xs-6"
                    lg="2"
                    md="3"
                    sm="4"
                  >
                    <div className="font-icon-detail">
                    <i className="tim-icons icon-gift-2" />
                      
                      {data.map((val)=>{
                        return <p>Gratuité du {val.DATE}</p>
                      })}
                      {data.map((val)=>{
                        return <h4>{val.Gratuité} DH</h4>
                      })}
                    </div>
                  </Col>
                </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
              
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category"></h5>
                    <CardTitle tag="h2">Performance</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Jours
                        </span>
                        <span className="d-block d-sm-none">
                        <i className="tim-icons icon-coins" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Mois
                        </span>
                        <span className="d-block d-sm-none">
                        <i className="tim-icons icon-money-coins" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Année
                        </span>
                        <span className="d-block d-sm-none">
                        <i className="tim-icons icon-calendar-60" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Derniers Tickets</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierTicket1} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierTicket2} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierTicket3} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierTicket4} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierTicket5}</td>
                      })}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
          <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Derniers Produits Annulés</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierProduitAnnulé1} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierProduitAnnulé2} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierProduitAnnulé3} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierProduitAnnulé4} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierProduitAnnulé5}</td>
                      })}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Top vente Produit</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.TopProduit1} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.TopProduit2} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.TopProduit3} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.TopProduit4} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.TopProduit5}</td>
                      })}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dernière Carte Annulée</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierCarteAnnulé1} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierCarteAnnulé2} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierCarteAnnulé3} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierCarteAnnulé4} </td>
                      })}
                    </tr>
                    <tr>
                    {data.map((val)=>{
                        return <td>{val.DernierCarteAnnulé5}</td>
                      })}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
         
        </Row>
      </div>
     
    </>
  );

}
}

export default Dashboard;
