
import React , {useState, useEffect} from "react";
import { Redirect } from "react-router";
import {Button, Card, CardHeader, CardBody, Row, Col,Input ,FormGroup, Form , CardFooter } from "reactstrap";
import logo from "assets/img/react-logo.png";
import routes from "routes.js";
import 'react-responsive-combo-box/dist/index.css';
import {CardTitle,Table} from "reactstrap";
import * as XLSX from 'xlsx';
import './article.css';
import Axios from "axios";
import Sidebar from "components/Sidebar/Sidebar.js";

function Articles(props) {
    
  const [data, setData] = useState( [] );
  const [data111, setData111] = useState( [] );
  const [data1, setData1] = useState( [] );
  const [category, setcategory] = useState( [] );
  const [data4, setData4] = useState( [] );
  const [q, setQ] = useState( "" );
  const [s, setS] = useState( "" );
  const [p, setP] = useState( "" );
  const [matricule, setMatricule] = useState( "" );
  const [nom, setNom] = useState( "" );
  const [prix, setPrix] = useState( "" );
  const [details, setDetail] = useState( "" );
  const [add, setAdd] = useState( false );
  const [update, setUpdate] = useState( false );
  const [gerer, setGerer] = useState( false );
  
  const [value, setValue] = useState('');

  function handleChange(event){
    
    setValue(event.target.value);
  };
   
    var IDmagasin = -1;
  
       
    const toggleSidebar = () => {
      document.documentElement.classList.toggle("nav-open");
      setsidebarOpened(!sidebarOpened);
    };
    const [sidebarOpened, setsidebarOpened] = React.useState(
      document.documentElement.className.indexOf("nav-open") !== -1
    );
    
    
useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/getArticles", {
    GetData: GetData,
  }).then((resp) =>{
    setData(resp.data)
    setData1(resp.data)
    setS(0);
    setP(0);
    
   
    
})}, []);

useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/getArticles", {
    GetData: GetData111,
  }).then((resp) =>{
    setData111(resp.data)
    
    
   
    
})}, []);

useEffect(()=>{
  Axios.post("https://api.livecaisse.com/api/getCategorie", {
    GetCategorie: GetCategorie,
  }).then((resp) =>{
    
    setcategory(resp.data)
    
})}, []);
function stock(){

  setData1(data.filter((row)=> 
  row.Stock == s));
  

}

function stockMin(){
  
  
  setData1(data.filter((rows)=> 
  Number(rows.Stock) <= Number(rows.QteMin)));
  

}
function logi(){
  
  Axios.post("https://api.livecaisse.com/Update/article" , {
    ArticleQuery:ArticleQuery
          }).then((resp1)=>{
            Axios.post("https://api.livecaisse.com/api/getArticles", {
              GetData: GetData,
                    }).then((resp) =>{
                      setData(resp.data)
                      setData1(resp.data)
                      setS(0);
                      setP(0); 
                  })
                      
          })
          
          Axios.post("https://api.livecaisse.com/Update/article" , {
    ArticleQuery:UpdateMag
          }).then((resp1)=>{
            
                      
          })
  
  
          

}
function logi111(){
  
  data111.map((mag)=>(
    Axios.post("https://api.livecaisse.com/Update/article" , {
    ArticleQuery:"UPDATE "+mag.IDmagasin+"_articles Set nom = '"+ nom+"' , information = '"+ details+"' , OPTIONS ='"+prix+"', categorie_id= "+value+" , update2 = 2 where matricule = '"+matricule+"'" 
          }).then((resp1)=>{
            Axios.post("https://api.livecaisse.com/api/getArticles", {
              GetData: GetData,
                    }).then((resp) =>{
                      setData(resp.data)
                      setData1(resp.data)
                      setS(0);
                      setP(0); 
                  })
                  Axios.post("https://api.livecaisse.com/Update/article" , {
                  ArticleQuery:"update Magasin set TableArticleUpdated = 0 where IDmagasin = "+mag.IDmagasin
                  }).then((resp1)=>{
            
                      
                  })
                      
          })
          
          
          
      ))


}

function logi3(){
  
  Axios.post("https://api.livecaisse.com/Update/article" , {
    
    ArticleQuery:ArticleAjout
          }).then((resp1)=>{
            Axios.post("https://api.livecaisse.com/api/getArticles", {
              GetData: GetData,
                    }).then((resp) =>{
                      setData(resp.data)
                      setData1(resp.data)
                      setS(0);
                      setP(0); 
                  })
                      
          })
         
          Axios.post("https://api.livecaisse.com/Update/article" , {
            ArticleQuery:UpdateMag
                  }).then((resp1)=>{
                    
                              
                  })
  
  
          

}
function logi33(){
  
  data111.map((mag)=>(
    Axios.post("https://api.livecaisse.com/Update/article" , {
    ArticleQuery:"INSERT INTO "+mag.IDmagasin+"_articles (matricule , nom , information , OPTIONS , categorie_id , update2) VALUES ('"+matricule+"','"+nom+"','"+details+"',"+prix+","+value+" , 0)" 
          }).then((resp1)=>{
            Axios.post("https://api.livecaisse.com/api/getArticles", {
              GetData: GetData,
                    }).then((resp) =>{
                      setData(resp.data)
                      setData1(resp.data)
                      setS(0);
                      setP(0); 
                  })
                  Axios.post("https://api.livecaisse.com/Update/article" , {
                  ArticleQuery:"update Magasin set TableArticleUpdated = 0 where IDmagasin = "+mag.IDmagasin
                  }).then((resp1)=>{
            
                      
                  })
                      
          })
          
          
          
      ))

}
useEffect(()=>{
  Axios.post("https://api.livecaisse.com/Update/article" , {
    
    ArticleQuery:DisplayUpdate
          }).then((resp) =>{
    
    if (resp.data.length>0) {
      setUpdate(true)
    }else{
      setUpdate(false)
    }
    
})}, []);
function logi2(){
  
  setS(0);
  setP(0);
  setGerer(false);
  
       

}



function popuptrue(e){
 
  
  setP(1);
  
  setAdd(true);
  setMatricule(e.currentTarget.id);
  


  
  
  setData4(data.filter((row)=> 
  row.matricule == e.currentTarget.id));
  

    
  data4.map((article)=>(
    setNom(article.nom)
      ))
      data4.map((article)=>(
        setPrix(article.OPTIONS)
          ))
          data4.map((article)=>(
            setDetail(article.information)
              ))
              data4.map((article)=>(
                setValue(article.categorie_id)
                  ))
                  
               
                      
}
function popupajoute(){
  setP(1);
  setAdd(false);
}
function popupaGerer(){
  setP(1);
  setGerer(true);
}

function All(){
  setData1(data);
 
  

}
const downloadExcel = ()=>{
  const workSheet = XLSX.utils.json_to_sheet(data1);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook,workSheet, "articles");

  let buf=XLSX.write(workBook,{bookType:"xlsx", type:"buffer"});
  XLSX.write(workBook,{bookType:"xlsx", type:"binary"});
  XLSX.writeFile(workBook,"ArticlesData.xlsx")

}
function search(rows){
  return rows.filter((row)=>
  row.nom.toLowerCase().indexOf(q)>-1 ||
  row.matricule.toLowerCase().indexOf(q)>-1 || 
  row.nom.indexOf(q)>-1 || 
  row.nom.toUpperCase().indexOf(q)>-1

  );
}


    
      if(props.location.state.detail == undefined  ){
        return <Redirect to="/admin/login" />
      }else
      {    
        var GetCategorie = "select * from "+props.location.state.detail+"_categories";
        var GetData  = "select * from "+props.location.state.detail+"_articles";
        var GetData111  = "SELECT * FROM Magasin WHERE IDclient IN (SELECT IDclient FROM Magasin WHERE IDmagasin ="+props.location.state.detail+")";
        var ArticleQuery = "UPDATE "+props.location.state.detail+"_articles Set nom = '"+ nom+"' , information = '"+ details+"' , OPTIONS ='"+prix+"', categorie_id= "+value+" , update2 = 2 where matricule = '"+matricule+"'" ;
        var ArticleAjout = "insert into "+props.location.state.detail+"_articles (matricule ,nom , information ,OPTIONS, categorie_id, update2 ) values ('"+matricule+"','"+nom+"','"+details+"','"+prix+"',"+value+" , 0)";
        var DisplayUpdate = "select UpdateArticle from Magasin where IDmagasin = "+props.location.state.detail+" AND UpdateArticle = 1";
        var UpdateMag = "update Magasin set TableArticleUpdated = 0 where IDmagasin = "+props.location.state.detail;
        IDmagasin = props.location.state.detail;
        
       
       
  return (p==0) ? (
    <>
    
    <Sidebar
               routes={routes}
               logo={{
                 outterLink: "",
                 text: "Dashboard",
                 imgSrc: logo,
               }}
               IDmagasin={IDmagasin}
               toggleSidebar={toggleSidebar}
             />
      <div className="content">
      
        <div>
        <label>Recherche Article</label>
          <Input type="text" value={q} onChange={(e) => setQ(e.target.value)} placeholder="Nom ou Matricule/ d'article"></Input><br/><br/>
        </div>
        
        <Button className="btn-fill" color="info" type="submit" onClick={All} >
                  Tous les Articles
        </Button>
        <Button className="btn-fill" color="secondary" type="submit" onClick={stock} >
                  Stock terminer
        </Button>
        <Button className="btn-fill" color="secondary" type="submit" onClick={stockMin} >
                  Quantité minimale
        </Button>
        <Button className="btn-fill" color="success" type="submit" onClick={downloadExcel} >
                  Export Excel
        </Button>
        {update ? ( <Button className="btn-fill" color="success" type="submit" onClick={popupajoute} >
                  Ajouter article
        </Button>):("")}
        <Button className="btn-fill" color="success" type="submit" onClick={popupaGerer} >
                   Gerer stock 
        </Button>
       
        
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Articles</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Matricule</th>
                      <th>Nom</th>
                      <th>Information</th>
                      <th>Stock</th>
                      <th className="text-center">Prix</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {search(data1).map((val)=>{
                      
                      return<tr>
                       <td>{val.matricule}</td>
                       <td>{val.nom}</td>
                       <td>{val.information}</td>
                       <td>{val.Stock}</td>
                       <td className="text-center">{val.OPTIONS}</td>
                       <td className="text-center">
                       {update ? ( <Button className="btn-fill" color="success" type="submit" onClick={popuptrue} id={val.matricule}>
                              Modifier
                       </Button>):("")}
                      
                         </td>
                     </tr>
                    }
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </div>
     
    </>
  ) : ( (gerer==false)?( <>
    <Sidebar
                 routes={routes}
                 logo={{
                   outterLink: "",
                   text: "Dashboard",
                   imgSrc: logo,
                 }}
                 IDmagasin={IDmagasin}
                 toggleSidebar={toggleSidebar}
               />
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Article</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                  <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Matricule Article</label>
                        {add ?(data4.map((article)=>(
                          
                           <Input
                           onChange={(e)=>{
                             setMatricule(e.target.value);
                           }}
                           desaible
                           placeholder={article.matricule}
                           type="text"
                           defaultValue={article.matricule}
                           disabled = "disabled"
                           
                         />
                         
                        ))):(<Input
                          onChange={(e)=>{
                            setMatricule(e.target.value);
                          }}
                          desaible
                          placeholder="Matricule"
                          
                          type="text"
                         
                        />)}
                        
                       
                        
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Nom Article</label>
                        {  /*add ?(data4.map((article)=>(
      setNom(article.nom)
        ))
        ):("")*/}
                        {add ?(data4.map((article)=>(
                           <Input
                           onChange={(e)=>{
                             setNom(e.target.value);
                           }}
                           desaible
                           placeholder={article.nom}
                           type="text"
                           defaultValue={article.nom}
                         />
                        ))):(<Input
                          onChange={(e)=>{
                            setNom(e.target.value);
                          }}
                          desaible
                          placeholder="Nom"
                          type="text"
                         
                        />)}
                        
                       
                        
                      </FormGroup>
                    </Col>
                   
                  </Row>
                  
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Information Article</label>
                        {/*add ?(data4.map((article)=>(
      setDetail(article.information)
        ))
        ):("")*/}
                        {add ?(data4.map((article)=>(
                           <Input
                           onChange={(e)=>{
                             setDetail(e.target.value);
                           }}
                           desaible
                           placeholder={article.information}
                           type="text"
                           defaultValue={article.information}
                         />
                        ))):(<Input
                          onChange={(e)=>{
                            setDetail(e.target.value);
                          }}
                          desaible
                          placeholder="Information"
                          type="text"
                         
                        />)}
                       
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Prix Article</label>
                        {/*add ?(data4.map((article)=>(
      setPrix(article.OPTIONS)
        ))
        ):("")*/}
                        {add ?(data4.map((article)=>(
                           <Input
                           onChange={(e)=>{
                             setPrix(e.target.value);
                           }}
                           desaible
                           placeholder={article.OPTIONS}
                           type="text"
                           defaultValue={article.OPTIONS}
                         />
                        ))):(<Input
                          onChange={(e)=>{
                            setPrix(e.target.value);
                          }}
                          desaible
                          placeholder="Prix"
                          type="text"
                         
                        />)}
                       
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label >Categorie</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="5">
                      <FormGroup>
                      {/*add ?(data4.map((article)=>(
      setValue(article.categorie_id)
        ))
        ):("")*/}
                        <select value={value} onChange={handleChange} className="Combobox" >
                          {category.map((category)=>(
                            <option value={category.id}>{category.designation}</option>
                          ))}
                        </select>
                        
                      </FormGroup>
                    </Col>
                  </Row>
                  
                </Form>
              </CardBody>
              <CardFooter>
                {add ? ( <div> <Button className="btn-fill" color="success" type="submit" onClick={logi} >
                  Save
                </Button> 
                <Button className="btn-fill" color="success" type="submit" onClick={logi111} >
                  Save for all
                </Button>
                <Button className="btn-fill" color="danger" type="submit" onClick={logi2} >
                  Cancel
                </Button> 
                </div>
                ):(<div>
                  <Button className="btn-fill" color="success" type="submit" onClick={logi3} >
                  Save
                </Button>
                <Button className="btn-fill" color="success" type="submit" onClick={logi33} >
                Save for all
                </Button>
                <Button className="btn-fill" color="danger" type="submit" onClick={logi2} >
                  Cancel
                </Button>
                </div> )}
                
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
  
          </Col>
        </Row>
      </div>
    </>):(<>
    
    <Sidebar
               routes={routes}
               logo={{
                 outterLink: "",
                 text: "Dashboard",
                 imgSrc: logo,
               }}
               IDmagasin={IDmagasin}
               toggleSidebar={toggleSidebar}
             />
      <div className="content">
      
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Gerer Stock</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>ID Transfere</label>
                        <Input
                         
                          desaible
                          placeholder="Mot de passe"
                          type="text"
                        />
                        
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>ID Depots Envois</label>
                        <Input
                          
                          placeholder="Nouveau mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>ID Depots Rrecu</label>
                        <Input
                         
                          placeholder="Confirmer mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Quantite</label>
                        <Input
                          
                          placeholder="Nouveau mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>BonReception</label>
                        <Input
                         
                          placeholder="Confirmer mot de passe"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={logi2}>
                  Save
                </Button>
                <Button className="btn-fill" color="danger" type="submit" onClick={logi2} >
                  Cancel
                 </Button> 
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">

          </Col>
        </Row>
      
    
      <div>
      <label>Recherche Article</label>
      </div>
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Articles</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Matricule</th>
                      <th>Nom</th>
                      <th>Information</th>
                      <th>Stock</th>
                      <th className="text-center">Prix</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {search(data4).map((val)=>{
                      
                      return<tr>
                       <td>{val.matricule}</td>
                       <td>{val.nom}</td>
                       <td>{val.information}</td>
                       <td>{val.Stock}</td>
                       <td className="text-center">{val.OPTIONS}</td>
                       <td className="text-center">
                       <Input
                type="checkbox"
                onChange={(e) => console.log(e.target.value)}
            />
                      
                         </td>
                     </tr>
                    }
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
        <div>
        <label>Recherche Article</label>
          <Input type="text" value={q} onChange={(e) => setQ(e.target.value)} placeholder="Nom ou Matricule/ d'article"></Input><br/><br/>
        </div>
        
       
              
       
        
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Articles</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Matricule</th>
                      <th>Nom</th>
                      <th>Information</th>
                      <th>Stock</th>
                      <th className="text-center">Prix</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {search(data1).map((val)=>{
                      
                      return<tr>
                       <td>{val.matricule}</td>
                       <td>{val.nom}</td>
                       <td>{val.information}</td>
                       <td>{val.Stock}</td>
                       <td className="text-center">{val.OPTIONS}</td>
                       <td className="text-center">
                       <Input
                type="checkbox"
                onChange={(e) => console.log(e.target.value)}
            />
                      
                         </td>
                     </tr>
                    }
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </div>
     
    </>) )  ;
}
}

export default Articles;
